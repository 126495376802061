import { CalendarDate, DatesI18n, DateRangeI18n } from "@gocardless/flux-react";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { useI18n } from "../../technical-integrations/i18n";
import { fromCalendarDate } from "../date-time";

export const useI18nLocale = (): DatesI18n & DateRangeI18n => {
  const { i18n } = useLingui();
  const getDateLabel = (date: CalendarDate) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [locale] = useI18n();
    return new Intl.DateTimeFormat(locale, { dateStyle: "full" }).format(
      fromCalendarDate(date)
    );
  };
  return {
    instructionsText: i18n._(
      t({
        id: "date.picker.instructions",
        message: "Arrow keys can navigate dates.",
      })
    ),
    previousMonthButtonLabel: i18n._(
      t({
        id: "date.picker.previous",
        message: "Previous",
      })
    ),
    nextMonthButtonLabel: i18n._(
      t({
        id: "date.picker.next",
        message: "Next",
      })
    ),
    dayInputLabel: i18n._(
      t({
        id: "date.picker.day",
        message: "Day",
      })
    ),
    dayInputPlaceholder: "dd",
    monthInputLabel: i18n._(
      t({
        id: "date.picker.month",
        message: "Month",
      })
    ),
    monthInputPlaceholder: "mm",
    yearInputLabel: i18n._(
      t({
        id: "date.picker.year",
        message: "Year",
      })
    ),
    yearInputPlaceholder: "yyyy",
    changeDateButtonLabel: i18n._(
      t({
        id: "date.picker.change.date",
        message: "Change date",
      })
    ),
    fromDateLabel: i18n._(
      t({
        message: "From",
      })
    ),
    toDateLabel: i18n._(
      t({
        message: "To",
      })
    ),
    applyButtonLabel: i18n._(
      t({
        message: "Apply",
      })
    ),
    clearButtonLabel: i18n._(
      t({
        message: "Clear",
      })
    ),

    getDaysOfWeek() {
      return {
        mon: {
          full: i18n._(
            t({
              id: "date.picker.monday.full",
              message: "Monday",
            })
          ),
          short: i18n._(
            t({
              id: "date.picker.monday.short",
              message: "Mo",
            })
          ),
        },
        tue: {
          full: i18n._(
            t({
              id: "date.picker.tuesday.full",
              message: "Tuesday",
            })
          ),
          short: i18n._(
            t({
              id: "date.picker.tuesday.short",
              message: "Tu",
            })
          ),
        },
        wed: {
          full: i18n._(
            t({
              id: "date.picker.wednesday.full",
              message: "Wednesday",
            })
          ),
          short: i18n._(
            t({
              id: "date.picker.wednesday.short",
              message: "We",
            })
          ),
        },
        thu: {
          full: i18n._(
            t({
              id: "date.picker.thursday.full",
              message: "Thursday",
            })
          ),
          short: i18n._(
            t({
              id: "date.picker.thursday.short",
              message: "Th",
            })
          ),
        },
        fri: {
          full: i18n._(
            t({
              id: "date.picker.friday.full",
              message: "Friday",
            })
          ),
          short: i18n._(
            t({
              id: "date.picker.friday.short",
              message: "Fr",
            })
          ),
        },
        sat: {
          full: i18n._(
            t({
              id: "date.picker.saturday.full",
              message: "Saturday",
            })
          ),
          short: i18n._(
            t({
              id: "date.picker.saturday.short",
              message: "Sa",
            })
          ),
        },
        sun: {
          full: i18n._(
            t({
              id: "date.picker.sunday.full",
              message: "Sunday",
            })
          ),
          short: i18n._(
            t({
              id: "date.picker.sunday.short",
              message: "Su",
            })
          ),
        },
      };
    },
    getDateLabel,
    getSelectedDateLabel(date) {
      return `${i18n._(
        t({
          id: "date.picker.selected.date",
          message: "Selected date:",
        })
      )} ${getDateLabel(date)}`;
    },
    getDayLabel(day) {
      return `${day}`;
    },
    getMonthYearLabel(date) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [locale] = useI18n();
      return new Intl.DateTimeFormat(locale, {
        month: "long",
        year: "numeric",
      }).format(fromCalendarDate(date));
    },
  };
};
